<template>
    <el-dialog :custom-class="'user-dialog'" :visible="dialogvisible" width="60%" @close="$emit('update:dialogvisible', false); visiblePassword=false">
        <div>
            <el-row class="iconRow">
                <!--<el-input size="small" class="margin-bottom-10" placeholder="Listede Olmayan Bir Icon Girebilirsiniz."-->
                          <!--v-model="sayfaForm.icon"></el-input>-->
                <el-col v-for="(item,index) in iconListe"
                    :key="item"
                    :label="item"
                    :value="item" :lg="4" :md="4" :sm="8" :xs="8"
                    style="border: 1px solid #9a9a9a; padding: 5px; height: 100px; text-align: center;display:table">
                    <div v-on:click="iconSelect(item)" class="iconCerceve" id="#iconCerceve">
                        <el-button type="text">
                            <i style="font-size: 24px;text-align: center" :class="item"></i>
                        </el-button>
                        <br>
                        <span class="icon-name" :data-class="item">{{item}}</span>
                    </div>
                </el-col>
            </el-row>

        </div>
    </el-dialog>
</template>

<script>
    import JQuery from 'jquery';
    let $ = JQuery;


    import iconJson from '../icon'
    import '@fortawesome/fontawesome-free/css/all.css'
    import '@fortawesome/fontawesome-free/js/all.js'

    import notification from '../notification'

    export default {
        name: "IconSelect",
        props: ['dialogvisible'],
        data(){
            return{
                iconListe: iconJson["v5.0.9"],
            }
        },
        methods: {
            iconSelect(value) {
                this.icon = value;
                this.$emit('iconSelect', value)
                $("body").find(".aktifIkon").removeClass("aktifIkon")
                $(".iconRow").find('span[data-class="' + value + '"]').closest(".iconCerceve").parent("div").addClass("aktifIkon");
                notification.Status("success", this, value + " iconu seçildi.")
            },
        }
    }
</script>

<style scoped>

</style>